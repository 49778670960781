import {
  defaultTypography,
  genPageTheme,
  PageTheme,
  shapes, UnifiedThemeOptions
} from '@backstage/theme';

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({colors: ['#00095B', '#00095B'], shape: shapes.wave2}),
  documentation: genPageTheme({colors: ['#028100', '#028100'], shape: shapes.wave2}),
  tool: genPageTheme({colors: ['#C10E4D', '#C10E4D'], shape: shapes.wave}),
  service: genPageTheme({colors: ['#0174EA', '#0174EA'], shape: shapes.wave}),
  website: genPageTheme({colors: ['#112B4E', '#112B4E'], shape: shapes.wave}),
  library: genPageTheme({colors: ['#007971', '#007971'], shape: shapes.wave}),
  other: genPageTheme({colors: ['#B84E00', '#B84E00'], shape: shapes.wave}),
  app: genPageTheme({colors: ['#B73EB7', '#B73EB7'], shape: shapes.wave}),
  apis: genPageTheme({colors: ['#960404', '#960404'], shape: shapes.wave}),
  card: genPageTheme({colors: ['#D52E09', '#D52E09'], shape: shapes.wave})
};

const defaultFontFamily = 'FordAntennaRegular, Arial, Roboto, San Serif';

export const defaultFordTypography = {
  ...defaultTypography,
  fontFamily: defaultFontFamily,
};

export const defaultFordTheme: Partial<UnifiedThemeOptions> = {
  fontFamily: defaultFontFamily,
  defaultPageTheme: 'home',
  pageTheme,
  components: {
    MuiLink: {
      styleOverrides: {
        root: ({theme}) => ({
          color: theme.palette.link,
          '&:hover': {
            color: theme.palette.linkHover
          }
        })
      }
    }
  }
};