import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes
} from '@backstage/theme';
import {defaultFordTheme, defaultFordTypography} from './baseTheme';

export const fordDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      type: 'dark',
      background: {
        default: '#1A1F26',
        paper: '#212830'
      },
      primary: {
        main: '#0093F0',
      },
      secondary: {
        main: '#0275EB',
      },
      error: {
        main: '#FF3030'
      },
      warning: {
        main: '#DA7029'
      },
      success: {
        main: '#31BA4C'
      },
      info: {
        main: '#F2F2F2',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#F2F2F2',
        disabled: '#9C9C9C'
      },
      divider: '#4D4D4D',
      navigation: {
        ...palettes.dark.navigation,
        background: '#212830',
        color: '#FFFFFF',
        indicator: '#0093F0',
        navItem: {
          hoverBackground: 'rgba(116,118,121,0.6)'
        }
      },
    },
    typography: defaultFordTypography
  }),
  ...defaultFordTheme,
  components: {
    ...defaultFordTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        contained: ({ theme }) => ({
          borderRadius: 30,
          color: '#00095B',
          backgroundColor: theme.palette.common.white,
          '&:disabled': {
            backgroundColor: '#8C8C8C',
            color: theme.palette.common.black
          },
          '&:hover': {
            backgroundColor: '#f2f2f2'
          }
        }),
        // required for MUI 4 components
        containedPrimary: ({ theme }) => ({
          borderRadius: 30,
          color: '#00095B',
          backgroundColor: theme.palette.common.white,
          '&:disabled': {
            backgroundColor: '#8C8C8C',
            color: theme.palette.common.black
          },
          '&:hover': {
            backgroundColor: '#f2f2f2'
          }
        }),
        // required for MUI 4 components
        containedSecondary: ({ theme }) => ({
          borderRadius: 30,
          color: '#00095B',
          backgroundColor: theme.palette.common.white,
          '&:disabled': {
            backgroundColor: '#8C8C8C',
            color: theme.palette.common.black
          },
          '&:hover': {
            backgroundColor: '#f2f2f2'
          }
        }),
        outlined: ({ theme }) => ({
          borderRadius: 30,
          color: theme.palette.common.white,
          borderColor: theme.palette.common.white,
          '&:disabled': {
            color: '#9c9c9c',
            borderColor: '#9c9c9c',
          },
          '&:hover': {
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            backgroundColor: '#FFFFFF1A'
          }
        }),
        // required for MUI 4 components
        outlinedPrimary: ({ theme }) => ({
          borderRadius: 30,
          color: theme.palette.common.white,
          borderColor: theme.palette.common.white,
          '&:disabled': {
            color: '#9c9c9c',
            borderColor: '#9c9c9c',
          },
          '&:hover': {
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            backgroundColor: '#FFFFFF1A'
          }
        }),
        // required for MUI 4 components
        outlinedSecondary: ({ theme }) => ({
          borderRadius: 30,
          color: theme.palette.common.white,
          borderColor: theme.palette.common.white,
          '&:disabled': {
            color: '#9c9c9c',
            borderColor: '#9c9c9c',
          },
          '&:hover': {
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            backgroundColor: '#FFFFFF1A'
          }
        }),
        text: ({ theme }) => ({
          color: theme.palette.common.white,
          '&:hover': {
            color: theme.palette.common.white,
            textDecoration: 'underline',
            textDecorationThickness: '0.1rem',
            textUnderlineOffset: '0.3rem',
            backgroundColor: 'transparent'
          },
          '&:disabled': {
            color: '#9c9c9c',
          }
        }),
        // required for MUI 4 components
        textPrimary: ({ theme }) => ({
          color: theme.palette.common.white,
          '&:hover': {
            color: theme.palette.common.white,
            textDecoration: 'underline',
            textDecorationThickness: '0.1rem',
            textUnderlineOffset: '0.3rem',
            backgroundColor: 'transparent'
          },
          '&:disabled': {
            color: '#9c9c9c',
          }
        }),
        // required for MUI 4 components
        textSecondary: ({ theme }) => ({
          color: theme.palette.common.white,
          '&:hover': {
            color: theme.palette.common.white,
            textDecoration: 'underline',
            textDecorationThickness: '0.1rem',
            textUnderlineOffset: '0.3rem',
            backgroundColor: 'transparent'
          },
          '&:disabled': {
            color: '#9c9c9c',
          }
        })
      }
    }
  }
});
