import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {ApiExplorerPage} from '@backstage/plugin-api-docs';
import {
    CatalogEntityPage,
    CatalogIndexPage,
    catalogPlugin
} from '@backstage/plugin-catalog';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {
    TechDocsIndexPage,
    techdocsPlugin,
    TechDocsReaderPage
} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';
import {
    AlertDisplay,
    OAuthRequestDialog,
    SignInPage,
    SignInProviderConfig,
} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import { ExplorePage, explorePlugin } from '@backstage-community/plugin-explore';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {microsoftAuthApiRef} from '@backstage/core-plugin-api';
import {shortcutsPlugin} from '@backstage-community/plugin-shortcuts';
import {badgesPlugin} from '@backstage-community/plugin-badges';
import {fordLightTheme} from './theme/light';
import {fordDarkTheme} from './theme/dark';
import {explorePage} from './components/explore/ExplorePage';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CodeIcon from '@material-ui/icons/Code';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import CloudIcon from '@material-ui/icons/Cloud';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import { HomePage } from '@bdrck/plugin-home';
import {dcsApis} from '@dcs/core-api';
import {UnifiedThemeProvider} from '@backstage/theme';
import {CustomProviderSettings} from './CustomProviderSettings';
import {VelauxPage} from '@bdrck/plugin-vela';
import { bedrockEntityColumns } from '@bdrck/backstage-plugin-entities';
import {JFrogLibVerPage} from 'backstage-plugin-jfrog-artifactory-libs';

const microsoftProvider: SignInProviderConfig = {
    id: 'microsoft',
    title: 'Ford',
    message: 'Sign in using Ford Azure AD',
    apiRef: microsoftAuthApiRef
};

const app = createApp({
    apis: [...apis, ...dcsApis],
    plugins: [shortcutsPlugin, badgesPlugin],
    components: {
        SignInPage: props => {
            return (
              <SignInPage
                    {...props}
                    auto
                    provider={microsoftProvider}
                />
            );
        },
    },
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate
        });
        bind(scaffolderPlugin.externalRoutes, {
            viewTechDoc: techdocsPlugin.routes.docRoot
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex
        });
        bind(explorePlugin.externalRoutes, {
            catalogEntity: catalogPlugin.routes.catalogEntity,
        });
    },
    icons: {
        infinity: AllInclusiveIcon,
        code: CodeIcon,
        metrics: AssessmentIcon,
        phone: SmartphoneIcon,
        cloud: CloudIcon
    },
    themes: [
        {
            id: 'light',
            title: 'Light',
            variant: 'light',
            Provider: ({children}) => (
              <UnifiedThemeProvider theme={fordLightTheme} children={children} />
            )
        },
        {
            id: 'dark',
            title: 'Dark',
            variant: 'dark',
            Provider: ({children}) => (
              <UnifiedThemeProvider theme={fordDarkTheme} children={children} />
            )
        }
    ],
    featureFlags: []
});



const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/catalog" element={<CatalogIndexPage initiallySelectedFilter='all' columns={bedrockEntityColumns}/>}/>
    <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage initialFilter='all'/>}/>
    <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}>
      <TechDocsAddons>
        <ReportIssue/>
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage/>}/>
    <Route path="/api-docs" element={<ApiExplorerPage initiallySelectedFilter='all'/>}/>
    <Route path="/search" element={<SearchPage/>}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage providerSettings={<CustomProviderSettings/>}/>}/>
    <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route path="/home" element={<HomePage />} />
    <Route path="/velaux" element={<VelauxPage />} />
    <Route path="/jfrog" element={<JFrogLibVerPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay/>
    <OAuthRequestDialog/>
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
