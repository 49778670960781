import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes
} from '@backstage/theme';
import {defaultFordTheme, defaultFordTypography} from './baseTheme';

export const fordLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      background: {
        default: '#F4F4F4',
        paper: '#FFFFFF'
      },
      primary: {
        main: '#102B4E'
      },
      secondary: {
        main: '#0142BD'
      },
      error: {
        main: '#D62D0A'
      },
      warning: {
        main: '#DA7029'
      },
      success: {
        main: '#008200'
      },
      info: {
        main: '#4D4D4D',
      },
      text: {
        primary: '#00095B',
        secondary: '#4D4D4D',
        disabled: '#8C8C8C'
      },
      navigation: {
        ...palettes.light.navigation,
        background: '#102B4E',
        color: '#FFFFFF',
        indicator: '#FFFFFF',
        navItem: {
          hoverBackground: 'rgba(116,118,121,0.6)'
        }
      },
      divider: '#C8C8C8'
    },
    typography: defaultFordTypography
  }),
  ...defaultFordTheme,
  components: {
    ...defaultFordTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: ({ theme }) => ({
          borderRadius: 30,
          color: theme.palette.common.white,
          backgroundColor: '#00095B',
          '&:disabled': {
            backgroundColor: '#6e6e6e',
            color: theme.palette.common.white
          },
          '&:hover': {
            backgroundColor: '#1700f4'
          }
        }),
        // required for MUI 4 components
        containedPrimary: ({ theme }) => ({
          borderRadius: 30,
          color: theme.palette.common.white,
          backgroundColor: '#00095B',
          '&:disabled': {
            backgroundColor: '#6e6e6e',
            color: theme.palette.common.white
          },
          '&:hover': {
            backgroundColor: '#1700f4'
          }
        }),
        // required for MUI 4 components
        containedSecondary: ({ theme }) => ({
          borderRadius: 30,
          color: theme.palette.common.white,
          backgroundColor: '#00095B',
          '&:disabled': {
            backgroundColor: '#6e6e6e',
            color: theme.palette.common.white
          },
          '&:hover': {
            backgroundColor: '#1700f4'
          }
        }),
        outlined: {
          borderRadius: 30,
          color: '#00095B',
          borderColor: '#00095B',
          '&:disabled': {
            color: '#6e6e6e',
            borderColor: '#6e6e6e',
          },
          '&:hover': {
            color: '#1700f4',
            borderColor: '#1700f4',
            backgroundColor: '#00095b0d'
          }
        },
        // required for MUI 4 components
        outlinedPrimary: {
          borderRadius: 30,
          color: '#00095B',
          borderColor: '#00095B',
          '&:disabled': {
            color: '#6e6e6e',
            borderColor: '#6e6e6e',
          },
          '&:hover': {
            color: '#1700f4',
            borderColor: '#1700f4',
            backgroundColor: '#00095b0d'
          }
        },
        // required for MUI 4 components
        outlinedSecondary: {
          borderRadius: 30,
          color: '#00095B',
          borderColor: '#00095B',
          '&:disabled': {
            color: '#6e6e6e',
            borderColor: '#6e6e6e',
          },
          '&:hover': {
            color: '#1700f4',
            borderColor: '#1700f4',
            backgroundColor: '#00095b0d'
          }
        },
        text: {
          color: '#00095B',
          '&:hover': {
            // color: '#1700f4',
            textDecoration: 'underline',
            textDecorationThickness: '0.1rem',
            textUnderlineOffset: '0.3rem',
            backgroundColor: 'transparent'
          },
          '&:disabled': {
            color: '#6e6e6e',
          }
        },
        // required for MUI 4 components
        textPrimary: {
          color: '#00095B',
          '&:hover': {
            // color: '#1700f4',
            textDecoration: 'underline',
            textDecorationThickness: '0.1rem',
            textUnderlineOffset: '0.3rem',
            backgroundColor: 'transparent'
          },
          '&:disabled': {
            color: '#6e6e6e',
          }
        },
        // required for MUI 4 components
        textSecondary: {
          color: '#00095B',
          '&:hover': {
            // color: '#1700f4',
            textDecoration: 'underline',
            textDecorationThickness: '0.1rem',
            textUnderlineOffset: '0.3rem',
            backgroundColor: 'transparent'
          },
          '&:disabled': {
            color: '#6e6e6e',
          }
        }
      }
    }
  }
});
